import React, { useState, useEffect } from 'react';
import { intialLoading, setIndex } from '../../store/screentransistion/actions';
import { saveUserDetails} from '../../store/trackinguser/actions';
import Layout from '../common/layoutred';
import Buttonn from '../../assets/images/backgrounds/submit_button.png';
import { fetchShuffle } from '../../store/shuffledata/operations';
import { Alert } from '@material-ui/lab';
import { isAndroid } from 'react-device-detect';
import './style.scss';
import fullScreen from '../../utils/fullScreen';
import { connect } from 'react-redux';
import {
  toggleMusic,
  toggleVoiceOver,
} from '../../store/screentransistion/actions';
import music from '../../assets/audio/BGMusic.mp3';
import PlayMusic from '../../utils/playMusic';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
window.gtag('event', 'REGISTRATION', {
  'event_category' : 'REGISTRATION',
  'event_label' : 'REGISTRATION'
});


const Registration = ({
  fetchShuffle,
  shuffleData,
  progress,
  languages,
  zones,
  saveUserDetails,
  audio,
  intialLoading,
  setIndex,
  gameSessionId,
  language,
  toggleMusic,
  toggleVoiceOver,
  userSelectedLanguage,
}) => {
  const [india] = zones;
  // const [english] = languages;
 
  const [data, setData] = useState({
    zone: india._id,
    lang: userSelectedLanguage,
    number: '',
  });
  
  const [selectedLanguage,setSelectedLanguage]=useState(userSelectedLanguage)
//terms
  const [isChecked, setIsChecked] = useState(false)
  const [hide, setHide]= useState(false)

  const handleCheckbox=(event)=>{
     setIsChecked(!isChecked)
     if(event.target.checked){
       setError(false)
       setMsg("")
     }
  }
  const toggleCheckbox=()=>{
    if(!isChecked){
      setError(false)
      setMsg("")
    }
    setIsChecked(!isChecked)
  }

  const activate = fullScreen();

  const [error, setError] = useState(false);
  const [msg, setMsg] = useState('Please enter valid details');

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
//warining localizing
  const[text3,setText3]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setText3("Maximum 10 numbers allowed")
  }
  if(selectedLanguage==="hi"){
    setText3("अधिकतम 10 नंबरों की अनुमति है")
  }
},[])
const[text4,setText4]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setText4("Please enter a number")
  }
  if(selectedLanguage==="hi"){
    setText4("एक संख्या दर्ज करें")
  }
},[])
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;

     const rExp = /^[0-9\b]+$/;
       if (name === 'number') {
      if (rExp.test(event.target.value)) {
        if (event.target.value.length <= 10) {
          setData({ ...data, [name]: value });
          setError(false);
        } else {
          setError(true);
          setMsg(text3);
          return;
        }
      } else {
        setData({ ...data, [name]: '' });
        setError(true);
        setMsg(text4);
        return;
      }
    }

    setData({ ...data, [name]: value });
  };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setData({ ...data, [name]: value });
  // };

  // useEffect(() => {
  //   const path = window.location.pathname;
  //   const number = path.replace('/', '');
  //   setData({ ...data, number: number });
  // }, []);

  // const register = (event) => {
  //   event.preventDefault();
  //   if (
  //     data.number === '' ||
  //     data.number === undefined ||
  //     data.number === null ||
  //     data.number === '/_' ||
  //     data.number === '/' ||
  //     data.number === `/''` ||
  //     data.number === `/'%20'`
  //   ) {
  //     setError(true);
  //     setMsg('Invalid Token');
  //     return;
  //   }
  //   if (data.lang && data.zone && data.number) {
  //     fetchShuffle(
  //       [data.lang, data.number],
  //       () => {
  //         // This is done so that audio controls are initiated as part of a user action
  //         toggleVoiceOver(true);
  //         toggleMusic(true);
  //         const audioRef = PlayMusic(music);
  //         audioRef.pause();

  //         intialLoading();
  //         setIndex(0);
  //         saveUserDetails(data);
  //         if (isAndroid) {
  //           activate();
  //         }
  //       },
  //       (err) => {
  //         setError(true);
  //         setMsg('NetWork Failed Please Check Network Connection');
  //       }
  //     );
  //   } else {
  //     setError(true);
  //     setMsg('Invalid Token');
  //   }
  // };

  //terms and conditions
  const terms = <text>
  Welcome to the Financial Journey mission. <br />
  By performing this task, You have agreed to the terms of the Task as well as the Website's rules on Personal Data Protection in accordance with the information below: <br /><br />
1.	Conditions for receiving Spins:<br /><br />
After logging in, You will receive multiple choice questions, to complete the task you need to answer all the displayed questions. <br />
The system will automatically add 15 Spins at the game "Spin of Fortune" after you complete the task. Your answer will not affect the number of Spins received. <br /> 

You can "Exit" from the unfinished quest and do it later, however after you complete it you won't be able to redo the quest. In other words, You can only complete the mission and get the Spins 01 (one) time during the “Round of Fortune” game period.<br />
In the event of any dispute arising from this Task, HDFC shall be the party to make the final decision.<br /><br />
2.	Protect personal data <br /><br />
2.1.	We - HDFC - are committed to making every effort and taking necessary measures to protect the information provided by you, including information about the phone number used to register. account, and other information arising in the course of using the services at this Website (“Data”). In addition to the aforementioned Data, We undertake not to seek to collect any further information from You in the course of performing the Tasks at this Website.<br /><br />
2.2.	When You register and perform the Tasks at this Website, We first obtain the right/agreement from You to collect, store, use, disseminate and share this Data at any any time. <br />
By providing Data to HDFC, You expressly agree that this Data will be maintained and processed by Us both within and outside India for various purposes, including to enable HDFC or authorized representatives of HDFC authenticate and collect Customer Data through legitimate information sources, make calls regarding products and services offered by or through through HDFC and/or HDFC's business partners or service providers, communicating product information, offering promotions and many other offers offered by HDFC's partners.<br /><br />

2.3.	While using these Sites, you agree, not to engage in illegal or unauthorized activities of any kind (including hacking, cracking or vandalizing any part of the Site). web).<br /><br />
2.4.	We protect Customer Data against unauthorized use in a similar way to how we protect our confidential information. However, under the following conditions, we may share Customer Data:<br /><br />
-	For the purpose of signing and performing transactions and contracts between You and HDFC and for the following specific other purposes (i) providing, looking up and using information from the Credit Information Center India national and credit information companies; (ii) advertising for HDFC's products and services, (iii) market research, internal analysis, customer experience enhancement;<br /><br />
-	Use the Data to improve the service and keep You updated on new products or other information that may be of interest to You. We may share some Customer Data with third parties with Your prior consent, who may approach/contact You to provide you with attractive offers through campaigns. advertising, information storage;<br /><br />
-	Shared within HDFC group for the purpose of management and internal control.<br /><br />
-	The data has been anonymized, encrypted so that the data subject can no longer be identified.<br /><br />
2.5.Note that the exclusions in this document will not restrict us from publishing business or field reports about customer behavior patterns, nor from sharing developed information. by HDFC provided that such information cannot be used to identify an individual.<br />

</text>;
const terms2 = <text>
  वित्तीय यात्रा मिशन में आपका स्वागत है। <br />
  इस कार्य को निष्पादित करके, आप नीचे दी गई जानकारी के अनुसार कार्य की शर्तों के साथ-साथ व्यक्तिगत डेटा संरक्षण पर वेबसाइट के नियमों से सहमत हैं: <br /><br />
  1. स्पिन प्राप्त करने की शर्तें:<br /><br />
  लॉग इन करने के बाद, आपको बहुविकल्पीय प्रश्न प्राप्त होंगे, कार्य को पूरा करने के लिए आपको सभी प्रदर्शित प्रश्नों के उत्तर देने होंगे। <br />
  आपके द्वारा कार्य पूरा करने के बाद सिस्टम "स्पिन ऑफ फॉर्च्यून" गेम में स्वचालित रूप से 15 स्पिन जोड़ देगा। आपका उत्तर प्राप्त स्पिनों की संख्या को प्रभावित नहीं करेगा। <br /> 

  आप अधूरी खोज से "बाहर" निकल सकते हैं और इसे बाद में कर सकते हैं, हालांकि इसे पूरा करने के बाद आप खोज को फिर से नहीं कर पाएंगे। दूसरे शब्दों में, आप केवल मिशन को पूरा कर सकते हैं और "राउंड ऑफ फॉर्च्यून" गेम अवधि के दौरान स्पिन 01 (एक) समय प्राप्त कर सकते हैं।<br />
  इस कार्य से उत्पन्न होने वाले किसी भी विवाद की स्थिति में, HDFC अंतिम निर्णय लेने वाला पक्ष होगा।<br /><br />

  2. व्यक्तिगत डेटा को सुरक्षित रखें <br /><br />
  2.1. हम - HDFC - आपके द्वारा प्रदान की गई जानकारी की सुरक्षा के लिए हर संभव प्रयास करने और आवश्यक उपाय करने के लिए प्रतिबद्ध हैं, जिसमें पंजीकरण के लिए उपयोग किए गए फ़ोन नंबर की जानकारी भी शामिल है। खाते, और इस वेबसाइट ("डेटा") पर सेवाओं का उपयोग करने के दौरान उत्पन्न होने वाली अन्य जानकारी। उपरोक्त डेटा के अलावा, हम इस वेबसाइट पर कार्य करने के दौरान आपसे कोई और जानकारी एकत्र नहीं करने का वचन देते हैं।<br /><br />
  2.2. जब आप इस वेबसाइट पर पंजीकरण करते हैं और कार्य करते हैं, तो हम किसी भी समय इस डेटा को एकत्र करने, संग्रहीत करने, उपयोग करने, प्रसारित करने और साझा करने के लिए सबसे पहले आपसे अधिकार/अनुबंध प्राप्त करते हैं। <br />
  HDFC को डेटा प्रदान करके, आप स्पष्ट रूप से सहमत हैं कि यह डेटा हमारे द्वारा India के भीतर और बाहर विभिन्न उद्देश्यों के लिए बनाए रखा जाएगा और संसाधित किया जाएगा, जिसमें HDFC या HDFC के अधिकृत प्रतिनिधियों को सक्षम करना और वैध सूचना स्रोतों के माध्यम से ग्राहक डेटा एकत्र करना शामिल है। HDFC और/या HDFC के व्यावसायिक भागीदारों या सेवा प्रदाताओं के माध्यम से या उनके माध्यम से पेश किए जाने वाले उत्पादों और सेवाओं के बारे में कॉल करना, उत्पाद जानकारी को संप्रेषित करना, HDFC के भागीदारों द्वारा पेश किए गए प्रचार और कई अन्य प्रस्तावों की पेशकश करना।<br /><br />

  2.3. इन साइटों का उपयोग करते समय, आप किसी भी प्रकार की अवैध या अनधिकृत गतिविधियों (साइट के किसी भी हिस्से को हैक करने, क्रैक करने या तोड़फोड़ करने सहित) में शामिल नहीं होने के लिए सहमत हैं। वेब)।<br /><br />
  2.4. हम अनधिकृत उपयोग के खिलाफ ग्राहक डेटा की रक्षा उसी तरह करते हैं जैसे हम अपनी गोपनीय जानकारी की रक्षा करते हैं। हालांकि, निम्नलिखित शर्तों के तहत, हम ग्राहक डेटा साझा कर सकते हैं:<br /><br />
- आपके और HDFC के बीच लेन-देन और अनुबंधों पर हस्ताक्षर करने और निष्पादित करने के उद्देश्य से और निम्नलिखित विशिष्ट अन्य उद्देश्यों के लिए (i) क्रेडिट सूचना केंद्र India राष्ट्रीय और क्रेडिट सूचना कंपनियों से जानकारी प्रदान करना, देखना और उपयोग करना; (ii) HDFC के उत्पादों और सेवाओं के लिए विज्ञापन, (iii) बाजार अनुसंधान, आंतरिक विश्लेषण, ग्राहक अनुभव में वृद्धि;<br /><br />
- सेवा को बेहतर बनाने के लिए डेटा का उपयोग करें और आपको नए उत्पादों या अन्य जानकारी से अपडेट रखें जो आपकी रुचि की हो सकती हैं। हम आपकी पूर्व सहमति से कुछ ग्राहक डेटा को तृतीय पक्षों के साथ साझा कर सकते हैं, जो आपको अभियानों के माध्यम से आकर्षक ऑफ़र प्रदान करने के लिए आपसे संपर्क/संपर्क कर सकते हैं। विज्ञापन, सूचना भंडारण;<br /><br />
- प्रबंधन और आंतरिक नियंत्रण के उद्देश्य से HDFC समूह के भीतर साझा किया गया.<br /><br />
- डेटा को गुमनाम कर दिया गया है, एन्क्रिप्ट किया गया है ताकि डेटा विषय की पहचान नहीं की जा सके।<br /><br />
2.5.ध्यान दें कि इस दस्तावेज़ में बहिष्करण हमें ग्राहक व्यवहार पैटर्न के बारे में व्यवसाय या फ़ील्ड रिपोर्ट प्रकाशित करने, और न ही विकसित जानकारी साझा करने से प्रतिबंधित नहीं करेगा। HDFC द्वारा बशर्ते कि ऐसी जानकारी का उपयोग किसी व्यक्ति की पहचान के लिए नहीं किया जा सकता है।<br />

</text>;
//terms and condition localized
const[conditions,setConditions]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setConditions(terms)
  }
  if(selectedLanguage==="hi"){
    setConditions(terms2)
  }
},[])

//Registration page terms and condition text localizing
const[text1,setText1]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setText1("agree with")
  }
  if(selectedLanguage==="hi"){
    setText1("से सहमत")
  }
},[])

const[text2,setText2]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setText2("Terms and Conditions")
  }
  if(selectedLanguage==="hi"){
    setText2("नियम और शर्तें")
  }
},[])

const[placeHolder,setPlaceHolder]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setPlaceHolder("Enter phone number")
  }
  if(selectedLanguage==="hi"){
    setPlaceHolder("फ़ोन नंबर दर्ज करें")
  }
},[])

  //start button
  const[start,setStart]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setStart("Start")
  }
  if(selectedLanguage==="hi"){
    setStart("प्रारंभ")
}
},[])

//alert terms and conditions
const[alertTc,setAlertTc]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setAlertTc("Please agree terms and conditions")
  }
  if(selectedLanguage==="hi"){
    setAlertTc("कृपया नियम और शर्तों से सहमत हों")
}
},[])
//Alert valid mobile number
// const[alertMobileno,setAlertMobileno]=useState("")
// useEffect(()=>{
//   if(selectedLanguage==="en"){
//     setAlertMobileno("Please enter valid number")
//   }
//   if(selectedLanguage==="hi"){
//     setAlertMobileno("कृपया मान्य संख्या दर्ज करें")
// }
// },[])
//terms and condition close
const[close,setClose]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setClose("Close")
  }
  if(selectedLanguage==="hi"){
    setClose("बंद करना")
}
},[])
const[tcTitle,setTcTitle]=useState("")
useEffect(()=>{
  if(selectedLanguage==="en"){
    setTcTitle("FINANCIAL JOURNEY CONDITIONS AND TERMS")
  }
  if(selectedLanguage==="hi"){
    setTcTitle("वित्तीय यात्रा की शर्तें और शर्तें")
}
},[])
  // const register = (event) => {
  //   event.preventDefault();
  //   console.log(data.number.length)

  //   const pattern=/^[6-9]{1}[0-9]{9}$/
  //   console.log("here",pattern.test(data.number))
  //   if (!pattern.test(data.number)) {
  //     setError(!pattern.test(data.number));
  //     setMsg(alertMobileno);
  //     return;
  //   }
  //   if(!isChecked){
  //     setError(true);
  //     setMsg(alertTc);
  //     return;
  //   }
  //   if (data.number.length === 10 && data.lang && data.zone) {  
  //     fetchShuffle(
  //       [data.lang, data.number],       
  //       () => {
  //         // This is done so that audio controls are initiated as part of a user action
  //         toggleVoiceOver(true);
  //         toggleMusic(true);
  //         const audioRef = PlayMusic(music);
  //         audioRef.pause();

  //         intialLoading();
  //         setIndex(0);
  //         saveUserDetails(data);
  //         if (isAndroid) {
  //           activate();
  //         }
  //       },
  //       (err) => {
  //         setError(true);
  //         setMsg('NetWork Failed Please Check Network Connection');
  //       }
  //     );
  //   } else {
  //     setError(true);
  //     setMsg('All fields are required');
  //   }
  // };

  useEffect(() => {
    const path = window.location.pathname;
    const number = path.replace('/', '');
    setData({ ...data, number: number });
  }, []);
  const register = (event) => {
    event.preventDefault();
    if (
      data.number === '' ||
      data.number === undefined ||
      data.number === null ||
      data.number === '/_' ||
      data.number === '/' ||
      data.number === `/''` ||
      data.number === `/'%20'`
    ) {
      setError(true);
      setMsg('Invalid Token');
      return;
    }
    if(!isChecked){
          setError(true);
          setMsg(alertTc);
          return;
        }
    if (data.lang && data.zone && data.number) {
      // resetShuffleData();
      // resetUserState()
      fetchShuffle(
        [data.lang, data.number],
        () => {
          // This is done so that audio controls are initiated as part of a user action
          toggleVoiceOver(true);
          toggleMusic(true);
          const audioRef = PlayMusic(music);
          audioRef.pause();

          intialLoading();
          setIndex(0);
          saveUserDetails(data);
          if (isAndroid) {
            activate();
          }
        },
        (err) => {
          setError(true);
          setMsg('NetWork Failed Please Check Network Connection');
        }
      );
    } else {
      setError(true);
      setMsg('Invalid Token');
    }
  };
  return (
    <>
      <Layout>
        <div className="registration__container">
          <form onSubmit={register}>
           
            {/* <div className="form__control">
              <select
                name="country"
                value={data.country}
                onChange={handleChange}
              >
                {zones
                  ? zones.map((zone) => (
                      <option key={zone._id} value={zone._id}>
                        {zone.zoneName}
                      </option>
                    ))
                  : null}
              </select>
            </div> */}
            {/* <div className="form__control">
              <input
                type="number"
                placeholder={placeHolder}
                name="number"
                value={data.number}
                onChange={handleChange}
              />
            </div> */}
            <div className="terms__condition">
             <div> <input  type="checkbox" checked={isChecked} onChange={handleCheckbox} style={{ verticalAlign: 'middle', transform:"scale(1.7)"}}/> 
              <Button style={{ marginTop: '1px'}}>
                <div className="termstext" >
                  <h2 style={{ color: 'white'}}><span onClick={toggleCheckbox}>{text1} </span><span onClick={handleClickOpen('paper')} style={{ color: 'black', textDecoration:"underline" }}>{text2}</span></h2>
                </div>
              </Button>
             <Dialog
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                {tcTitle}
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  > 
                  {conditions}
                    {/* {[...new Array(1)]
                      .map(
                        () => ``
                      )
                      .join('\n')} */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>
                    <h2>{close}</h2>
                  </Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
            </Dialog>
             </div>
            </div>
            <div
              type="submit"
              className="form__control start_button"
              onClick={register}
            >
              <img src={Buttonn} alt="start_image" />
              <div type="submit" className="button__text">
                {start}
              </div>
            </div>
            <div className="form__control error__msg">
              {error ? (
                <Alert severity="error">
                  <h3>{msg}</h3>
                </Alert>
              ) : null}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    progress: state.progress,
    languages: state.intialize.languages,
    zones: state.intialize.zones,
    audio: state.shuffleData,
    language: state.trackingUser.userDeatils,
    userSelectedLanguage:state.trackingUser.lang,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    fetchShuffle: (language, success, failure) =>
      dispatch(fetchShuffle(language, success, failure)),
    saveUserDetails: (details) => dispatch(saveUserDetails(details)),
    intialLoading: () => dispatch(intialLoading()),
    setIndex: (index) => dispatch(setIndex(index)),
    toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
    toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
  
  };
};

export default connect(mapStateToProps, mapDispathToProps)(Registration);
