import React, { useState, useEffect } from 'react';
import Layout from '../common/layoutred';
import Button from '../../assets/images/backgrounds/submit_button.png';
import './style.scss';
import { connect } from 'react-redux';
import { lang } from '../../store/trackinguser/actions';
window.gtag('event', 'LANGUAGE_SELECTION', {
  'event_category' : 'LANGUAGE_SELECTION',
  'event_label' : 'LANGUAGE_SELECTION'
});

const LanguageSelection = ({
  languages,
 saveInitialLanguage,
 
}) => {
 const [lang,setLang]=useState("en")
const handleChange=(e)=>{
  setLang(e.target.value)
  }

  const next=(e)=>{
  saveInitialLanguage(lang) 
  }
  
  return (
    <>
      <Layout>
        <div className="language__container">
          <form>
            <div className="form__control">
                <select name="lang" value={lang.lang} onChange={handleChange}>
                {languages
                  ? languages.map((lan) => (
                      <option key={lan.value} value={lan.value}>
                        {lan.language}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            <div className="form__control next_button" onClick={next} >
              <img src={Button} alt="next_image" />
              <div type="submit" className="button__text">
                Next
              </div>
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

const mapState = (state)=>{
  return {
    languages: state.intialize.languages,
    lan: state.trackingUser.saveInitialLanguage
  }
}

const mapDispathToProps = (dispatch) => {
  return {
      saveInitialLanguage:(language)=>dispatch(lang(language))
  }
}

export default connect(mapState,mapDispathToProps) (LanguageSelection);