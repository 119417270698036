import axios from 'axios';

export let headers = {
  'Tenant-Access-Token': 'MmM1M2Q4ZDc4NDExOGRiN2FlMDY2OWU0YzhhYTUzNTU=',
  'Tenant-Access-Key': '-qnJcwElya1ILg',
};

export const BASE_URL = 'https://immersive-game-india.confirmu.com';

export const api = axios.create({
  baseURL: BASE_URL,
  headers,
});

